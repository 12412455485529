
.img-logo {
    width: 170px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}


.form-100 {
    width: 100% !important;
}


.img-logo img {
    width: 100%;
}


.boton-estado {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #707070;
}


.trru{
    background-color: #049336;
}


.fall{
    background-color: #C10707;
}


.w-100 {
    width: 100px;
}


.w-200 {
    width: 200px;
}


.contiene-img img {
    width: 100%;
    height: 100%;

}


.boton-file {
    display: none;
}


.css-13xfq8m-MuiTabPanel-root {
    padding: 0px !important;
}




.titulo-imprimir {
    color:#ff9800; 
    text-align: center;
    font-weight: bold;
    font-size: 60px;
}


.img-logo-imprimir {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}


.img-logo-imprimir img {
    width: 220px;
}



/* SlideShow */
.contenedor-principal {
    width: 100% !important;
    height: calc(100vh - 70px);
    margin: 0px !important;
    padding: 0px !important;
    margin-top: 0px !important;
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}


.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    min-height: 400px;
    background: #000000;
    border: 2px solid #000;
    box-shadow: 24;
    padding: 40px;
}


.modal-style2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    min-height: 400px;
    background: white;
    border: 2px solid #000;
    box-shadow: 24;
    padding: 40px;
}



/* Todo Home */
.todo-home {
    background-image: url('./assets/empresa/campopollos.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 0.1px;
}


.carta {
    border: 3px solid #ed3237;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    background-image: url('./assets/empresa/madera.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    text-decoration: none !important;
    margin-top: 50px !important;
}

.carta h2 {
    font-size: 40px;
    width: 50%;
    color: #ffcc29;
    padding-left: 10px;
    flex: 1;
    /* justify-self: center; */
}


.item {
    /* flex-basis: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    flex: 1;
}


.item img {
    width: 300px;
}

.cardMedia-pdf {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}











/* Footer */
.footer {
    display: flex;
    justify-content: space-evenly;
    background-image: url('./assets/empresa/madera.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    text-decoration: none;
    margin: 50px auto;
    padding: 40px;
    min-height: 320px;
    width: 75%;
}


/* .itemmm {
    flex-basis: 30%;
    height: 100%;
} */

.titleFooter {
    font-size: 30px;
    color: #ffcc29;
    text-align: center;
}


.redes {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
}

.redes .icn {
    width: 45px;
    height: 45px;
    border: 1px solid white;
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
    font-size: 23px;
    color: white;
}


.redes .icn:hover {
    background-color: #ffcc29;
    color: #ed3237;
    border: 1px solid #ffcc29;
}


/* .legal-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0px;
    padding: 0px;
    padding-bottom: 20px;
}

.legal-list li {
    list-style: none;
    width: 100%;
}

.legal-list a {
    display: block;
    text-decoration: none;
    color: #fff;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    cursor: pointer;
}


.legal-list a:hover {
    color: #ed3237;
} */



/* Tablas */
.amarillo tr th{
    background-color: #f9a825 !important;
}


.verde tr th{
    background-color: #2e7031 !important;
}
